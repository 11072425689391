<script setup lang="ts">
  import InfoboxDefault from '../../Partials/Infobox/InfoboxDefault.vue'

  const componentMap: ComponentMap = {
    yellow: InfoboxDefault,
    default: InfoboxDefault,
  }

  const infoboxData = defineProps<{
    data: InfoboxData
  }>()

  const selectedComponent = computed(
    () => componentMap[infoboxData.data.display_hint] ?? InfoboxDefault
  )
</script>

<template>
  <section>
    <component
      :is="selectedComponent"
      class="cXenseParse"
      :infoboxData="infoboxData?.data"
    />
  </section>
</template>
